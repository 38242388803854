import React, { Component } from 'react';
import { CreateSecret } from './CreateSecret';
import { SecretLink } from './SecretLink';
import { Encryptor } from '../utils/Encryptor';
import { StatusListener } from './StatusListener';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = { secretId: null, secretUrl: '' };
        this.createEncryptedMessage = this.createEncryptedMessage.bind(this);
    }

    render() {
        if (this.state.secretUrl) {
            return (
                <React.Fragment>
                    <h1 className="my-3">Share a secret</h1>
                    <SecretLink url={this.state.secretUrl} />
                    <StatusListener id={this.state.secretId} />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <h1 className="my-3">Share a secret</h1>
                    <CreateSecret onSubmit={this.createEncryptedMessage} />
                </React.Fragment>
            );
        }
    }

    async createEncryptedMessage(message, ttl, selfdestruct) {
        const key = await Encryptor.generateKey();
        const encodedKey = await Encryptor.exportKey(key);
        const { iv, ciphertext } = await Encryptor.encrypt(message, key)
        const secret = Encryptor.packSecret(iv, ciphertext);

        fetch('/api/secret/',
            {
                method: 'POST',
                mode: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ secret, ttl, selfdestruct }),
            })
            .then(response => {
                if (!response.ok) throw Error(response.statusText);
                return response;
            })
            .then(response => response.text())
            .then(secretId => {
                console.log('Secret created: ' + secretId);
                this.setState({ secretId: secretId, secretUrl: `${window.location.origin}/s/${secretId}#${encodedKey}` });
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({ error: true });
            });
    }
}
