import React, { Component } from 'react';

export class CreateSecret extends Component {
    static displayName = CreateSecret.name;

    constructor(props) {
        super(props);
        this.state = { ttl: 3600, selfdestruct: true };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        this.setState({ [event.target.id]: value });
    }

    async handleSubmit(event) {
        event.preventDefault();
        await this.props.onSubmit(this.state.secret, Number(this.state.ttl), this.state.selfdestruct);
    }

    render() {
        return (
            <form>
                <div className="mb-3">
                    <label htmlFor="secret" className="form-label">Secret</label>
                    <textarea id="secret" className="form-control" rows="5"
                        placeholder="Message to share securely" aria-describedby="secretHelp" value={this.state.secret} onChange={this.handleChange}></textarea>
                    <div id="secretHelp" className="form-text">The message will be encrypted in the browser before it's sent to the server.</div>
                </div>
                <div className="mb-3">
                    <label htmlFor="ttl" className="form-label">Expire in</label>
                    <select id="ttl" className="form-select" value={this.state.ttl} onChange={this.handleChange}>
                        <option value="900">15 mins</option>
                        <option value="3600">1 hour</option>
                        <option value="43200">12 hours</option>
                        <option value="86400">1 day</option>
                        <option value="604800">7 days</option>
                    </select>
                </div>
                <div className="mb-3">
                    <div className="form-check col-sm">
                        <input id="selfdestruct" className="form-check-input" type="checkbox" checked={this.state.selfdestruct} onChange={this.handleChange} />
                        <label className="form-check-label" htmlFor="flexCheckDefault">Destroy on read</label>
                    </div>
                </div>
                <button id="createButton" type="button" className="btn btn-outline-primary mb-3" onClick={this.handleSubmit}>Encrypt message</button>
            </form>
        );
    }
}
