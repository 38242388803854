import React, { Component } from 'react';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <React.Fragment>
                <main className="container">
                    {this.props.children}
                </main>
             </React.Fragment>
        );
    }
}
