import { Base64Url } from './Base64Url';

export const Encryptor = {
    generateKey: generateKey,
    exportKey: exportKey,
    importKey: importKey,
    encrypt: encrypt,
    decrypt: decrypt,
    packSecret: packSecret,
    unpackSecret: unpackSecret,
}

async function generateKey() {
    const key = await window.crypto.subtle.generateKey(
        {
            name: "AES-GCM",
            length: 256,
        },
        true,
        ["encrypt", "decrypt"]
    );
    return key;
}

async function encrypt(data, key) {
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encodedData = new TextEncoder().encode(data);
    const ciphertext = await window.crypto.subtle.encrypt(
        {
            name: "AES-GCM",
            iv: iv
        },
        key,
        encodedData
    );

    return {
        iv,
        ciphertext,
    }
}

async function decrypt(ciphertext, iv, key) {
    const encoded = await window.crypto.subtle.decrypt({
        name: 'AES-GCM',
        iv: iv,
    }, key, ciphertext);
    const decoded = new TextDecoder().decode(encoded);

    return decoded;
}

function pack(buffer) {
    const s = String.fromCharCode(...new Uint8Array(buffer));
    return Base64Url.encode(s);
}

function packSecret(iv, ciphertext) {
    const s = String.fromCharCode(...iv, ...new Uint8Array(ciphertext));
    return Base64Url.encode(s);
}

function unpack(packed) {
    const string = Base64Url.decode(packed)
    const buffer = new ArrayBuffer(string.length)
    const bufferView = new Uint8Array(buffer)

    for (let i = 0; i < string.length; i++) {
        bufferView[i] = string.charCodeAt(i)
    }

    return buffer
}

function unpackSecret(secret) {
    const buffer = unpack(secret);
    const iv = new Uint8Array(buffer, 0, 12);
    const ciphertext = new Uint8Array(buffer, 12);
    return { iv, ciphertext };
}

async function exportKey(key) {
    const rawKey = await window.crypto.subtle.exportKey('raw', key);
    return pack(rawKey);
}

async function importKey(base64Key) {
    const rawKey = unpack(base64Key);
    const key = await window.crypto.subtle.importKey(
        'raw',
        rawKey,
        { name: "AES-GCM", length: 256 },
        true,
        ["encrypt", "decrypt"]);

    return key;
}