import React, { Component } from 'react';

export class SecretLink extends Component {
    static displayName = SecretLink.name;

    constructor(props) {
        super(props);
        this.copyLink = this.copyLink.bind(this);
    }

    render() {
        return (
            <div className="mb-3">
                <label htmlFor="link" className="form-label">Link</label>
                <div className="input-group">
                    <button className="btn btn-outline-secondary" type="button" id="button-copy" onClick={this.copyLink}>Copy</button>
                    <input id="link" type="text" className="form-control" readOnly placeholder=""
                        aria-label="Resulting link to secret" aria-describedby="button-copy" value={this.props.url} />
                </div>
            </div>
        );
    }

    copyLink() {
        navigator.clipboard.writeText(this.props.url).then(function () {
            console.log('Good copy to clipboard');
        }, function () {
            console.log('Fail copy to clipboard');
        });
    }

}