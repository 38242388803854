import React, { Component } from 'react';
import { Encryptor } from '../utils/Encryptor';

export class ViewSecret extends Component {
    static displayName = ViewSecret.name;

    constructor(props) {
        super(props);
        this.state = { message: '' };
        this.decryptMessage = this.decryptMessage.bind(this);
    }

    async componentDidMount() {
        const { match: { params } } = this.props;
        const id = params.id;
        const key = this.props.location.hash.substr(1);
        console.log(`id=${id}, key=${key}`);
        await this.decryptMessage(id, key);
    }

    render() {
        if (this.state.error) {
            return (
                <React.Fragment>
                    <h1 className="my-3">Missing secret</h1>
                </React.Fragment>
            );
        } else if (this.state.message) {
            return (
                <React.Fragment>
                    <h1 className="my-3">View secret</h1>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="viewsecret" className="form-label">Secret</label>
                            <textarea id="viewsecret" name="secret" className="form-control" rows="5"
                                aria-describedby="secretHelp" value={this.state.message} readOnly></textarea>
                            <div id="secretHelp" className="form-text">This message may or may not be viewable a second time.</div>
                        </div>
                    </form>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    async decryptMessage(id, encodedKey) {
        fetch('/api/secret/' + id)
            .then(response => {
                if (!response.ok) throw Error(response.statusText);
                return response;
            })
            .then(response => response.text())
            .then(async (secret) => {
                console.log('Success:', secret);
                const key = await Encryptor.importKey(encodedKey);
                const { iv, ciphertext } = Encryptor.unpackSecret(secret);
                const message = await Encryptor.decrypt(ciphertext, iv, key);
                this.setState({ message: message });
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({ error: true });
            });
    }
}
