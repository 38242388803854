import React, { Component } from 'react';

export class StatusListener extends Component {
    static displayName = StatusListener.name;

    constructor(props) {
        super(props);
        this.state = { items: [] };
        this.appendToSseOutput = this.appendToSseOutput.bind(this);
        this.source = null;
    }

    componentDidMount() {
        this.listen(this.props.id);
    }

    componentWillUnmount() {
        if (this.source) {
            console.log('SSE close on unmount');
            this.source.close();
        }
    }

    render() {
        const listItems = this.state.items.map((str, index) =>
            <li key={index}>{str}</li>
        );
        return (
            <ul id="sseOutput">{listItems}</ul>
        );
    }

    listen(id) {
        console.log('Setting upp SSE listener: ' + id);
        this.appendToSseOutput('Listening for pickup of secret...');

        this.source = new EventSource('/sse/secret?id=' + id);
        this.source.onopen = (event) => { console.log('SSE connection established: ' + event.target.readyState); };
        this.source.onerror = (event) => { console.log('SSE error: ' + event.target.readyState); };

        this.source.onmessage = (event) => {
             this.appendToSseOutput(event.data);
        }

        this.source.addEventListener('hello', function (event) {
            console.log('SSE hello: ' + event.data);
        });

        this.source.addEventListener('pickup', (event) => {
            console.log('SSE pickup: ' + event.data);
            this.appendToSseOutput(event.data);
        });

        this.source.addEventListener('close', (event) => {
            console.log('SSE close: ' + event.data);
            this.source.close();
        });
    }

    appendToSseOutput(text) {
        this.setState((state, props) => ({ items: state.items.concat(text) }))
    }

}